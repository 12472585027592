<template>
  <div>
    <b-card-title>จัดการไฟล์ i18n</b-card-title>
    <b-card v-for="lang in langsSupport" :key="lang.local_code">
      <div class="d-flex align-items-center">
        <p class="mb-0 mr-2">{{ lang.label }} <br />({{ lang.local_code }})</p>
        <b-form-file
          v-model="files[lang.local_code]"
          placeholder="อัปโหลดไฟล์ translation.json ที่นี้"
          accept="json"
          no-drop
        />
      </div>
      <button-widget
        text="อัปโหลด"
        block
        size="sm"
        class="mt-1"
        variant="danger"
        :disabled="!files[lang.local_code]"
        @click="uploadFileTranslation(lang.local_code)"
      />
      <button-widget
        text="ดูตัวอย่างไฟล์ล่าสุด"
        block
        size="sm"
        class="mt-1"
        variant="primary"
        @click="showPreviewFile(lang.local_code)"
      />
    </b-card>

    <b-modal
      ref="refModalPreviewFileTranslation"
      :title="`รายละเอียดไฟล์ ${previewForm.title}`"
      hide-footer
      size="xl"
      @hide="resetPreviewForm"
    >
      <pre>{{ previewForm.data }}</pre>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: {},
      previewForm: {
        title: '',
        data: {},
      },
      langsSupport: [],
    }
  },
  created() {
    this.fetchAllLangSupport()
  },
  methods: {
    async fetchAllLangSupport() {
      const resp = await this.api
        .getV2('/api/application/config?key=1134f13a-4cba-4bc0-bb18-df7d70d28c92')
        .catch(() => null)
      // console.log('getStatusAppMaintenance', resp)

      if (resp && typeof resp === 'object' && 'app_maintenance' in resp) {
        // console.log('fetchAllLangSupport => ', resp)
        this.langsSupport = [...resp.langs_support]
      } else {
        // console.log('fetchAllLangSupport => error')
      }
    },
    uploadFileTranslation(key = '') {
      if (!key) return

      const file = this.files[key]
      if (!file) return

      if (file.type !== 'application/json' && file.name !== 'translation.json') {
        this.gDisplayToast(
          'ไฟล์ไม่ถูกต้อง',
          'ไฟล์ต้องเป็น .json เท่านั้น และมีชื่อ translation.json เท่านั้น',
          'danger',
        )
        return
      }

      const reader = new FileReader()

      reader.onload = ev => {
        try {
          const textToJson = JSON.parse(ev.target.result)
          if (textToJson && typeof textToJson === 'object') {
            this.uploadFileToServer(file, textToJson, key)
          } else {
            this.gDisplayToast(
              'ไฟล์ไม่ถูกต้อง',
              'แปลงไฟล์เพื่อนำไปใช้งานไม่ได้ โปรจตรวจสอบอีกครั้งก่อนอัปโหลด',
              'danger',
            )
          }
        } catch (error) {
          this.gDisplayToast('ไฟล์ไม่ถูกต้อง', 'แปลงไฟล์เพื่อนำไปใช้งานไม่ได้ โปรจตรวจสอบอีกครั้งก่อนอัปโหลด', 'danger')
        }
      }
      reader.readAsText(file)
    },
    async uploadFileToServer(file, _, key) {
      // console.log('uploadFileToServer', { file, key })
      // return
      const formData = new FormData()
      formData.append('files', file)
      formData.append('langue', key)
      this.gOpenPageLoading()
      const resp = await this.api.postV2('api/admin/upload-file/file-translate', formData, this)
      if (resp && resp.code === 200) {
        this.gDisplayToast('อัปโหลดไฟล์สำเร็จ')
      } else {
        this.gDisplayToast('อัปโหลดไฟล์ไม่สำเร็จ', JSON.stringify(resp.data), 'danger')
      }
      this.gClosePageLoading()
    },
    async showPreviewFile(key = '') {
      if (!key) return
      this.previewForm.title = `${key}`.toUpperCase()
      this.gOpenPageLoading()
      const resp = await this.api.getV2(`/api/application/translate-menu?filename=${key}`)
      this.gClosePageLoading()

      if (resp && typeof resp === 'object') {
        this.previewForm.data = { ...resp }
        this.$refs.refModalPreviewFileTranslation.show()
      } else {
        this.gDisplayToast('ไม่พบข้อมูลไฟล์นี้', '', 'danger')
      }
    },
    resetPreviewForm() {
      this.previewForm.title = ''
      this.previewForm.data = {}
    },
  },
}
</script>

<style lang="scss" scoped></style>
